.about__sec {
    background: #0d0909;
}


.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;
}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg, 
        transparent,
        var(--color-primary),
        transparent
    );
    display: grid;
    place-items: center;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about__me-image:hover {
    transform: rotate(0);
}

.about__content p {
  margin-top: -0.5rem;
  font-size: 17px;
  letter-spacing: 1px;
}

.stacks ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
  }
  
  /* .stacks ul li {
    padding-left: 10px;
  } */


  /* ======== MEDIA QUERIES (MEDIUM DEVICES) ========== */
 

  @media screen and (max-width: 1024px) {
     .about__sec {
        height: 120%;
     }


    .about__container {
     grid-template-columns: 1fr;
     gap: 0;
    }
    
    .about__me {
     width:50%;
     margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }

}



@media screen and (max-width: 800px) {
    .about__sec {
        height: 120%;
    }
    
}

@media screen and (max-width: 750px) {
    .about__sec {
        height: 120%;
    }
    
}

@media screen and (max-width: 660px) {
    .about__sec {
        height: 120%;
    }
    
}

/* ======== MEDIA QUERIES (SMALL DEVICES) ========== */

@media screen and (max-width: 600px) {
   .about__me {
       width: 65%;
       margin: 0 auto 3rem;
   }

   .about__content {
       text-align: center;
   }

   .about__content p {
       margin: 1.5rem 0;
   }

   .about__sec {
       height: 130%;
   }
}