.exp__sec {
    background: #0d0909;
    height: max-content;
    padding-top: 5rem;
}

.container.experience__container {
 width: 70%;
 padding-bottom: 4rem;
}

.experience {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.details {
    color: var(--color-light);
    font-weight: 300;
    display: block;
   /* margin: 0.8rem auto 0; */
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: white;
}



/*==============================MEDIA QUERIES (MEDIUM DEVICES) ==========================*/
@media screen and (max-width: 1024px) {
    .container.experience__container {
        width: 60%;
    }
}


/*==============================MEDIA QUERIES (SMALL DEVICES) ==========================*/
@media screen and (max-width: 600px) {    
    .container.experience__container {
        width: var(--container-width-sm);
    }  

    .details {
        width: var(--container-width-sm);
    }
}



@media screen and (max-width: 400px) {
    .exp__sec {
        padding-top: 5rem;
    }
}


@media screen and (max-width: 375px) {
    .exp__sec {
        padding-top: 4rem;
    }
}
