.myportfolio {
  height: 200%;
}



.portfolio__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    gap: 25px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 45px;
    margin: 0 auto;
    max-width: 1000px;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    width: 100%;
    display: block;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 0.5rem 0 0.5rem;
    font-size: 17px;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    font-size: 13.5px;
}

/*==============================MEDIA QUERIES (MEDIUM DEVICES) ==========================*/
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }  
}

@media screen and (max-width: 660px) {
    .portfolio__container {
        margin-left: 25px;
    }
    
}

@media screen and (max-width: 630px) {
    .portfolio__container {
        margin-left: 10px;
    }
    
}

/*==============================MEDIA QUERIES (SMALL DEVICES) ==========================*/
@media screen and (max-width: 600px) {    
    .portfolio__container {
        grid-template-columns: 1fr;
        margin: 0 auto;
        gap: 1rem;
    }  

} 
