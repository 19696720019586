header {
    height: max-content;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

.header__container p {
    font-size: 19px;
    letter-spacing: 1px;
}

.mkb {
    color: var(--color-primary);
}

/*.headerpic {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
} */


/* ======== CTA ========== */
 .cta {
     margin-top: 2.5rem;
     display: flex;
     gap: 1.2rem;
     justify-content: center;

 }

 /* ======== HEADER SOCIALS ========== */
.header__socials {
 display: flex;
 flex-direction: row;
 justify-content: center;
 gap: 5rem;
 font-size: 2rem;
}

/* ======== SCROLL DOWN ========== */
/*.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
} */

/* ======== MEDIA QUERIES (MEDIUM DEVICES) ========== */

@media screen and (max-width: 1024px) {
    header {
        height: max-content;
    }
    
}

/*@media screen and (max-width: 1050px) {
    header {
        height: 90vh;
    }
    
}

@media screen and (max-width: 950px) {
    header {
        height: 90vh;
    }
    
}

@media screen and (max-width: 912px) {
    header {
        height: 50vh;
    }
    
}

@media screen and (max-width: 850px) {
    header {
        height: 90vh;
    }
    
}


@media screen and (max-width: 820px) {
    header {
        height: max-content;
    }
    
}

@media screen and (max-width: 810px) {
    header {
        height: 90vh;
    }
    
}

@media screen and (max-width: 768px) {
    header {
        height: 60vh;
    }
    
}

@media screen and (max-width: 700px) {
    header {
        height: 100vh;
    }
    
}


@media screen and (max-width: 660px) {
    header {
        height: 95vh;
    }
    
}  */


/* ======== MEDIA QUERIES (SMALL DEVICES) ========== */

@media screen and (max-width: 600px) {
    header {
        height: max-content;
    }
   
   /* header p {
        text-align: left;
    } */
}



@media screen and (max-width: 280px) {
    header {
        height: max-content;
    }
}

