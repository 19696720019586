.contact__sec {
  height: 85vh;
}


.left-section{
    margin-top: 20px;
    float: left;
    width: 45%; 
  }

  .img-section img {
    max-width: 100%;
    height: auto;
    margin-left: 20%;
    margin-top: 10px;
    border-radius: 2%;
    width: 70%;
   /* box-shadow: 10px 10px 5px -3px rgba(80,75,75,0.75);
  -webkit-box-shadow: 10px 10px 5px -3px rgba(80,75,75,0.75);
  -moz-box-shadow: 10px 10px 5px -3px rgba(80,75,75,0.75); */
  }
  .right-section {
    width: 55%;
    float: right;
    padding-left: 40px;
    padding-top: 52px;
  }

  .contact__option {
      background: var(--color-bg-variant);
      padding: 1.2rem;
      border-radius: 1.2rem;
      border: 1px solid transparent;
      transition: var(--transition);
      text-align: center;
  }

 

.sendmail {
  display: inline-block;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  color:#150f0f;
  background: rgb(110, 216, 110);
  padding: 0.75rem 1.2rem;
}

.sendmail:hover {
  
  color: #150f0f;
}

/*.mailing {
  padding-top: 20px;
  padding-left: 2px;
} */

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.socials__header {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  color: var(--color-primary-variant);
  margin-left: 1.5rem;

}

.socials__contact {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
  margin-left: 2.1rem;
}

.socials__contact a {
  background: var(--color-bg-variant);
  color: var(--color-white);
  padding: 1.2rem;
  border-radius: 0.7rem;
  display: flex;
  border: 1px solid transparent;
}

.socials__contact a:hover {
  background: transparent;
  color: var(--color-primary);
  border-color: var(--color-bg);
}






/*==============================MEDIA QUERIES (MEDIUM DEVICES) ==========================*/
@media screen and (max-width: 1024px) {
  .left-section {
      margin-top: 40px;
      width: 60%;
  }  
  .img-section img {
    width: 100%;
  }

  .right-section {
    width: 100%;
    display: block;
    margin-top: 40px;
    margin-right: 20px;
  }

  .contact__sec {
    height: 100vh; 
 }  

 

}

@media screen and (max-width: 1000px) {
  .contact__sec {
      height: 170vh;
  }

  
  
}

@media screen and (max-width: 990px) {
  .contact__sec {
      height: 170vh;
  }
  

}

@media screen and (max-width: 825px) {
  .contact__sec {
      height: 100vh;
  }


  .contact__option p {
    text-align: center;
  }
}



/*==============================MEDIA QUERIES (SMALL DEVICES) ==========================*/
@media screen and (max-width: 600px) {    
  .contact__sec {
     height: 130vh; 
  }  

 
} 

@media screen and (max-width: 480px) {
  .contact__sec {
      height: 90vh;
  }

  .socials__header {
    margin-left: 1rem;
  }

  .socials__contact {
    margin-left: 1.5rem;
  }
}

@media screen and (max-width: 395px) {
  .contact__sec {
      height: 90vh;
  }

}

@media screen and (max-width: 375px) {
  .contact__sec {
      height: 110vh;
  }

}

